.container-learning {
    h2 {
        padding-top: 20px;
    }

    h3.topic-header {
        font-size: 20px;
        color: #48BF53;
        text-decoration: underline;
    }

    .topic {
        padding: 15px;
    }

    .back-button {
        padding-bottom: 20px;
    }

    .technical-specs {
        border: 1px solid black;
        border-radius: 3px;

        th {
            background-color: #ebebeb;
            border-bottom: 1px solid black;
            padding: 5px;
        }

        td {
            padding: 5px;
        }
    }

    h3.topic-header:hover {
        color: #8ae280;
    }

    a {
        color: #48BF53;
        text-decoration: none;
    }

    a:hover {
        color: #8ae280;
    }

    .learning-button {
        color: white;
    }
    .learning-button:hover {
        color: black;
    }

    figcaption {
        font-size: 14px;
        color: rgb(143, 143, 143);
        font-style: italic;
    }
}